import { BookmarkAddOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { useSelector } from "react-redux";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { useBlockchainAddressBook } from "../../../providers/BlockchainAddressBookProvider";
import { Address } from "../../../types";
import {
  toBlockchainAddressUrl,
  toShortHash,
} from "../../../utils/blockchain-utils";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { selectBlockchainAddressBookMap } from "../blockchain.selectors";

interface IProps {
  address: Address;
  className?: string;
  fallback?: string;
}

export default function BlockchainAddressBook({
  address,
  className,
  fallback,
}: IProps) {
  const addressBookMap = useSelector(selectBlockchainAddressBookMap);
  const configs = useSelector(selectBlockchainConfigs);
  const addressBook = addressBookMap[address];
  const { openAddressBookForm } = useBlockchainAddressBook();

  const handleAddToAddressBook = () => {
    openAddressBookForm({ address });
  };

  return addressBook ? (
    <div className={classNames("group flex items-center", className)}>
      <Tooltip title={addressBook.address}>
        <a
          className="link"
          href={toBlockchainAddressUrl(configs?.addressExplorerUrl, address)}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {addressBook.alias}
        </a>
      </Tooltip>
      <CopyToClipboard className="hidden group-hover:block" text={address} />
    </div>
  ) : (
    <div className={classNames("group flex items-center gap-1", className)}>
      <a
        className="link"
        href={toBlockchainAddressUrl(configs?.addressExplorerUrl, address)}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        {fallback || toShortHash(address)}
      </a>
      <BookmarkAddOutlined
        className="hidden group-hover:block cursor-pointer animate-fade-in"
        color="success"
        fontSize="small"
        onClick={handleAddToAddressBook}
      />
    </div>
  );
}
