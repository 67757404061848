import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getBlockchainAddressBooksAction,
  getSmartDealsAction,
  getSmartDealsStatusCountsAction,
} from "./blockchain.effects";
import { countReset } from "console";

export const selectBlockchainState = (state: RootState) => state.blockchain;

export const selectSmartDeals = createSelector(
  selectBlockchainState,
  (state) => state.smartDeals || []
);

export const selectIsGetSmartDealsPending = createSelector(
  selectBlockchainState,
  (state) => state[getSmartDealsAction.pending.type]
);

export const selectSmartDealsPagination = createSelector(
  selectBlockchainState,
  (state) => state.pagination
);

export const selectSmartDealsStatusCounts = createSelector(
  selectBlockchainState,
  (state) => state.statusCounts
);

export const selectSmartDealsDraftCount = createSelector(
  selectSmartDealsStatusCounts,
  (counts) => counts?.Draft
);

export const selectSmartDealsBlockchainCount = createSelector(
  selectSmartDealsStatusCounts,
  (counts) => counts.Blockchain
);

export const selectSmartDealsPromptPayUnderReviewCount = createSelector(
  selectSmartDealsStatusCounts,
  (counts) => counts.PromptPayUnderReview
);

export const selectIsGetSmartDealsStatusCountsPending = createSelector(
  selectBlockchainState,
  (state) => state[getSmartDealsStatusCountsAction.pending.type]
);

export const selectSmartDealsFilters = createSelector(
  selectBlockchainState,
  (state) => state.filters
);

export const selectSmartDealsStatusFilter = createSelector(
  selectSmartDealsFilters,
  (filters) => filters.status
);

export const selectSmartDealsSearchFilter = createSelector(
  selectSmartDealsFilters,
  (filters) => filters.search
);

export const selectSmartDealsOrderByFilter = createSelector(
  selectSmartDealsFilters,
  (filters) => filters.orderBy
);

export const selectBlockchainAddressBooks = createSelector(
  selectBlockchainState,
  (state) => (state.addressBooks || []).sortBy("alias", "asc")
);

export const selectIsGetBlockchainAddressBooksPending = createSelector(
  selectBlockchainState,
  (state) => state[getBlockchainAddressBooksAction.pending.type]
);

export const selectBlockchainAddressBookMap = createSelector(
  selectBlockchainAddressBooks,
  (addressBooks) =>
    addressBooks.reduce((map, addressBook) => {
      map[addressBook.address] = addressBook;
      return map;
    }, {})
);
