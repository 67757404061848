import { createContext, useCallback, useContext, useMemo } from "react";
import CommandPalette, { Command } from "react-command-palette";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBlockchainAddressBooks,
  selectIsGetBlockchainAddressBooksPending,
} from "../modules/blockchain/blockchain.selectors";
import { blockchainActions } from "../modules/blockchain/blockchain.state";
import BlockchainAddressBookForm from "../modules/blockchain/components/BlockchainAddressBookForm";
import { BlockchainAddressBook } from "../modules/blockchain/types";
import { useModal } from "./ModalProvider";

interface BlockchainAddressBookContextType {
  openAddressBookForm: (addressBook?: Partial<BlockchainAddressBook>) => void;
}

const BlockchainAddressBookContext =
  createContext<BlockchainAddressBookContextType>(null);

const CommandIds = {
  AddNewAddress: "add-new-address",
};

export default function BlockchainAddressBookProvider({ children }) {
  const addressBooks = useSelector(selectBlockchainAddressBooks);
  const isGetAddressBooksPending = useSelector(
    selectIsGetBlockchainAddressBooksPending
  );
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const commands = useMemo(() => {
    return [
      {
        id: CommandIds.AddNewAddress,
        name: "Add New Address",
        command: () => {},
      },
      ...addressBooks.map((addressBook) => ({
        id: addressBook.address,
        name: `${addressBook.alias} (${addressBook.address})`,
        command: () => {},
      })),
    ];
  }, [addressBooks]);

  const openAddressBookForm = useCallback(
    async (addressBook?: Partial<BlockchainAddressBook>) => {
      const newAddressBook = await openModal((props) => (
        <BlockchainAddressBookForm {...props} addressBook={addressBook} />
      ));
      if (newAddressBook) {
        dispatch(blockchainActions.saveAddressBook(newAddressBook));
      }
    },
    [openModal]
  );

  const handleCommandClick = useCallback(
    (command: Command) => {
      switch (command.id as unknown as string) {
        case CommandIds.AddNewAddress:
          openAddressBookForm();
          break;

        default:
          openAddressBookForm(
            addressBooks.find((ab) => ab.address === (command.id as any))
          );
          break;
      }
    },
    [openAddressBookForm, addressBooks]
  );

  return (
    <BlockchainAddressBookContext.Provider value={{ openAddressBookForm }}>
      {children}
      <div className="hidden">
        {/* @ts-ignore */}
        <CommandPalette
          commands={commands}
          placeholder={
            isGetAddressBooksPending
              ? "Loading..."
              : "Search Blockchain Address..."
          }
          hotKeys={["command+shift+f"]}
          closeOnSelect={true}
          resetInputOnOpen
          onSelect={handleCommandClick}
        />
      </div>
    </BlockchainAddressBookContext.Provider>
  );
}

export const useBlockchainAddressBook = () =>
  useContext(BlockchainAddressBookContext);
