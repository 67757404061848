import { Drawer } from "@mui/material";
import { createContext, useState } from "react";

export interface BottomSheetProps {
  component: JSX.Element | null;
  onClose?: () => void;
}

export const BottomSheetContext = createContext<
  (props: BottomSheetProps | null) => void
>(() => {});

export default function BottomSheetProvider({ children }) {
  const [bottomSheet, setBottomSheet] = useState<BottomSheetProps | null>();

  const handleClose = () => {
    setBottomSheet(null);
    bottomSheet?.onClose?.();
  };

  return (
    <BottomSheetContext.Provider value={setBottomSheet}>
      <>
        {children}
        <Drawer
          anchor="bottom"
          open={!!bottomSheet}
          SlideProps={{ className: "h-screen" }}
          style={{ zIndex: 1202 }}
          disableEnforceFocus
          onClose={handleClose}
        >
          {bottomSheet?.component}
        </Drawer>
      </>
    </BottomSheetContext.Provider>
  );
}
