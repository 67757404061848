import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteForeverOutlined, MoreVert } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import { blockchainApi } from "../../../http/blockchain.api";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { blockchainActions } from "../blockchain.state";
import { BlockchainAddressBook } from "../types";

interface IProps {
  addressBook?: Partial<BlockchainAddressBook>;
  close: (addressBook?: BlockchainAddressBook) => void;
}

export default function BlockchainAddressBookForm({
  addressBook,
  close,
}: IProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    name: Yup.string().required().trim().min(1),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      address: addressBook?.address ?? "",
      name: addressBook?.alias ?? "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onSubmit = async (value) => {
    try {
      setIsSubmitting(true);
      const addressBook = await blockchainApi.saveBlockchainAddressBook({
        address: value.address,
        alias: value.name,
      });
      close(addressBook);
      enqueueSnackbar(`Address book saved successfully`, {
        variant: "info",
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    await blockchainApi.deleteBlockchainAddressBook(addressBook?.address);
    dispatch(blockchainActions.deleteAddressBook(addressBook?.address));
    close();
  };

  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <form className="p-4 w-[440px]" onSubmit={form.handleSubmit(onSubmit)}>
        <header className="modal-header text-lg flex items-center justify-between">
          {addressBook?.alias ? "Edit" : "Add"} Blockchain Address Book
          {addressBook && (
            <IconButton size="small" onClick={showMenu}>
              <MoreVert />
            </IconButton>
          )}
        </header>

        <div className="flex flex-col gap-6">
          <TextField
            className="w-full"
            {...form.register("address")}
            label="Address"
            variant="filled"
            size="small"
            helperText="Address (must start with ‘0x’)"
            error={!!form.formState.errors?.address}
            autoFocus
          />

          <TextField
            className="w-full"
            {...form.register("name")}
            label="Name"
            variant="filled"
            size="small"
            helperText="Alias name for the address"
            error={!!form.formState.errors?.name}
          />
        </div>

        <footer className="modal-footer flex flex-row-reverse gap-4">
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>

          <Button type="button" variant="text" onClick={() => close()}>
            Cancel
          </Button>
        </footer>
      </form>
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <div className="flex flex-col px-1">
          <Button
            className="justify-start"
            variant="text"
            color="error"
            startIcon={<DeleteForeverOutlined />}
            disabled={!addressBook?.alias || isSubmitting}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </MoreMenu>
    </>
  );
}
