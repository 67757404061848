import {
  Add,
  DeleteForever,
  Edit,
  InboxOutlined,
  MoreVert,
  Payments,
  PriorityHigh,
} from "@mui/icons-material";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import useDeleteConfirm from "../../../hooks/use-delete-confirm";
import { treasuryApi } from "../../../http";
import { useModal } from "../../../providers/ModalProvider";
import { TreasuryAccountBalance } from "../../../types";
import { toBlockchainAddressUrl } from "../../../utils/blockchain-utils";
import { getTreasuryAccountBalanceName } from "../../../utils/coimex-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import WidgetList from "../../dashboard/components/WidgetList";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { useTokenTransferModal } from "../providers/TokenTransferModalProvider";
import { getAccountBalancesActionsAction } from "../treasury.effects";
import {
  selectAccountBalances,
  selectIsGetAccountBalancesPending,
} from "../treasury.selectors";
import AccountBalanceForm from "./AccountBalanceForm";
import BlockchainAddressBook from "../../blockchain/components/BlockchainAddressBook";

const renderHeader = () => (
  <div className="flex justify-between items-center">
    <span>Account</span>
    <span className="mr-5">Balance</span>
  </div>
);

const renderItem = (accountBalance: TreasuryAccountBalance, showMenu) => {
  return (
    <div className="flex justify-between items-center text-sm">
      <BlockchainAddressBook address={accountBalance.account} />

      <div className="flex items-center gap-1">
        {accountBalance.notifyError && (
          <Tooltip title={accountBalance.notifyError}>
            <PriorityHigh fontSize="small" color="warning" />
          </Tooltip>
        )}
        {formatNumberWithSeparator(accountBalance.balance, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}{" "}
        {accountBalance.token.symbol}
        <MoreVert
          className="opacity-50 cursor-pointer"
          fontSize="small"
          onClick={(e) => showMenu(e, accountBalance)}
        />
      </div>
    </div>
  );
};

export default function AccountBalances() {
  const accountBalances = useSelector(selectAccountBalances);
  const isPending = useSelector(selectIsGetAccountBalancesPending);
  const [selectedMenuAccountBalance, setSelectedMenuAccountBalance] =
    useState<TreasuryAccountBalance | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const { openModal } = useModal();
  const { openTransferModal } = useTokenTransferModal();
  const confirmDelete = useDeleteConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const openAccountBalanceForm = async (
    accountBalance?: TreasuryAccountBalance
  ) => {
    if (
      await openModal(
        (props) => (
          <AccountBalanceForm {...props} accountBalance={accountBalance} />
        ),
        {
          closeOnClickOutside: false,
        }
      )
    ) {
      dispatch(getAccountBalancesActionsAction());
    }
  };

  const handleEdit = () => {
    closeMenu();
    openAccountBalanceForm(selectedMenuAccountBalance);
  };

  const handleDeposit = async () => {
    closeMenu();
    if (
      await openTransferModal({
        to: selectedMenuAccountBalance.account,
        tokenAddress: selectedMenuAccountBalance.token.address,
      })
    ) {
      dispatch(getAccountBalancesActionsAction());
    }
  };

  const handleDelete = async () => {
    closeMenu();
    if (
      await confirmDelete({
        title: getTreasuryAccountBalanceName(selectedMenuAccountBalance),
        onConfirm: () =>
          treasuryApi.deleteAccountBalance(selectedMenuAccountBalance.id),
      })
    ) {
      enqueueSnackbar(
        `Account Balance ${getTreasuryAccountBalanceName(
          selectedMenuAccountBalance
        )} removed successfully.`,
        { variant: "info" }
      );
      dispatch(getAccountBalancesActionsAction());
    }
  };

  const showMenu = (
    e: React.MouseEvent<HTMLButtonElement>,
    accountBalance: TreasuryAccountBalance
  ) => {
    e.stopPropagation();
    setSelectedMenuAccountBalance(accountBalance);
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full p-widget">
        <div className="WidgetHeader flex justify-between items-center">
          Account Balances
          <IconButton size="small" onClick={() => openAccountBalanceForm()}>
            <Add />
          </IconButton>
        </div>
        <div className="mt-4">
          <WidgetList
            items={accountBalances}
            itemsContainerClassName="h-[264px] overflow-y-auto"
            renderHeader={renderHeader}
            renderItem={(accountBalances) =>
              renderItem(accountBalances, showMenu)
            }
            renderEmpty={() => (
              <div className="mt-24 w-full flex justify-center gap-1 opacity-75">
                <InboxOutlined /> Your account list is empty
              </div>
            )}
          />
        </div>
      </div>
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <div className="flex flex-col px-1">
          <Button
            className="justify-start"
            variant="text"
            color="info"
            startIcon={<Edit />}
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="darkGold"
            startIcon={<Payments />}
            onClick={handleDeposit}
          >
            Deposit
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="error"
            startIcon={<DeleteForever />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </MoreMenu>
    </LoadingOverlay>
  );
}
