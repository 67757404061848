import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { blockchainApi } from "../../../http/blockchain.api";
import {
  AccountBalanceNotifyConditions,
  Address,
  Erc20TokenInfo,
  TreasuryAccountBalance,
} from "../../../types";
import { isValidBlockchainAddress } from "../../../utils/blockchain-utils";
import { TreasuryAccountBalanceNotifyConditionText } from "../../../utils/coimex-utils";
import {
  selectCommodityTokens,
  selectIsGetCommodityTokensPending,
} from "../../commodity-tokens/commodity-tokens.selectors";
import {
  selectBlockchainNativeToken,
  selectCommodityExchangeTokens,
  selectIsGetConfigsPending,
} from "../../settings/settings.selectors";
import { treasuryApi } from "../../../http";
import { useSnackbar } from "notistack";
import { selectBlockchainAddressBookMap } from "../../blockchain/blockchain.selectors";
import { blockchainActions } from "../../blockchain/blockchain.state";

interface IProps {
  accountBalance?: TreasuryAccountBalance;
  close?: (modified?: boolean) => void;
}

export default function AccountBalanceForm({ accountBalance, close }: IProps) {
  const nativeToken = useSelector(selectBlockchainNativeToken);
  const commodityExchangeTokens = useSelector(selectCommodityExchangeTokens);
  const commodityTokens = useSelector(selectCommodityTokens);
  const isGetConfigsPending = useSelector(selectIsGetConfigsPending);
  const isGetCommodityTokensPending = useSelector(
    selectIsGetCommodityTokensPending
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedToken, setSelectedToken] = useState<Erc20TokenInfo>();
  const [isGetSelectedTokenPending, setIsGetSelectedTokenPending] =
    useState(false);
  const addressBookMap = useSelector(selectBlockchainAddressBookMap);
  const validationSchema = Yup.object().shape({
    account: Yup.string()
      .required()
      .test("valid", function (value) {
        return isValidBlockchainAddress(value);
      }),
    tokenAddress: Yup.string()
      .required()
      .test("valid", function (value) {
        return value == nativeToken?.address || isValidBlockchainAddress(value);
      }),
    notifyOn: Yup.array().of(
      Yup.object().shape({
        checked: Yup.boolean().required(),
        condition: Yup.string().oneOf(AccountBalanceNotifyConditions),
        amount: Yup.number().optional().when("checked", {
          is: true,
          then: Yup.number().positive().required(),
        }),
      })
    ),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      account: accountBalance?.account ?? "",
      accountAlias: accountBalance?.accountAlias ?? "",
      tokenAddress: accountBalance?.token.address ?? "",
      notifyOn: AccountBalanceNotifyConditions.map((condition) => {
        const notify = accountBalance?.notifyOn?.find(
          (n) => n.condition == condition
        );
        return {
          checked: !!notify,
          condition,
          amount: notify?.amount ?? 0,
        };
      }),
    },
  });
  const tokens = useMemo(
    () =>
      (
        [
          nativeToken,
          ...commodityExchangeTokens,
          ...commodityTokens,
        ] as Erc20TokenInfo[]
      )
        .filter(Boolean)
        .distinct((t) => t.address),
    [nativeToken, commodityExchangeTokens, commodityTokens]
  );
  const addressBook = useMemo(
    () => addressBookMap[form.getValues("account")],
    [addressBookMap, form.watch("account")]
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getSearchedToken = async (tokenAddress: Address) => {
    try {
      setIsGetSelectedTokenPending(true);
      const token = tokens.find((t) => t.address == tokenAddress);
      if (token) {
        setSelectedToken(token);
      } else {
        setSelectedToken(null);
        if (isValidBlockchainAddress(tokenAddress)) {
          setSelectedToken(await blockchainApi.getTokenInfo(tokenAddress));
        }
      }
    } finally {
      setIsGetSelectedTokenPending(false);
    }
  };

  const patchFormValue = (key, value) => {
    form.setValue(key, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async (value) => {
    try {
      setIsSubmitting(true);
      const accountBalanceNotifier = await treasuryApi.saveAccountBalance({
        id: accountBalance?.id,
        account: value.account,
        accountAlias: value.accountAlias?.trim()
          ? value.accountAlias.trim()
          : null,
        tokenAddress: value.tokenAddress,
        notifyOn: value.notifyOn
          .filter((n) => n.checked)
          .map((n) => ({
            condition: n.condition,
            amount: n.amount,
          })),
      });

      if (accountBalanceNotifier.accountAlias) {
        dispatch(
          blockchainActions.saveAddressBook({
            address: accountBalanceNotifier.account,
            alias: accountBalanceNotifier.accountAlias,
          })
        );
      }

      close(true);
      enqueueSnackbar(
        `Account balance ${accountBalance ? "edited" : "added"} successfully`,
        {
          variant: "info",
        }
      );
    } catch {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const tokenAddress = form.getValues("tokenAddress");
    if (tokenAddress) {
      getSearchedToken(tokenAddress);
    }
  }, [form.watch("tokenAddress"), tokens]);

  useEffect(() => {
    if (addressBook) {
      form.setValue("accountAlias", addressBook.alias);
    }
  }, [addressBook]);

  return (
    <form className="p-4 w-[440px]" onSubmit={form.handleSubmit(onSubmit)}>
      <header className="modal-header text-lg">
        {accountBalance ? "Edit" : "Add"} Account Balance
      </header>

      <div className="flex flex-col gap-6">
        <TextField
          className="w-full"
          {...form.register("account")}
          label="Account"
          variant="filled"
          size="small"
          helperText="Account address (must start with ‘0x’)"
          error={!!form.formState.errors?.account}
          autoFocus
        />

        <TextField
          className="w-full"
          {...form.register("accountAlias")}
          label="Account Alias (Optional)"
          variant="filled"
          size="small"
          helperText="Alias name for the account"
          error={!!form.formState.errors?.accountAlias}
          disabled={!!addressBook}
          InputLabelProps={{ shrink: !!form.watch("accountAlias") }}
        />

        <Autocomplete
          value={form.watch("tokenAddress")}
          onChange={(_, value) => patchFormValue("tokenAddress", value)}
          freeSolo
          options={tokens.map((t) => t.address)}
          renderInput={(params) => (
            <div className="relative">
              <TextField
                {...params}
                label="Token"
                variant="filled"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment className="-mr-7" position="end">
                      {isGetSelectedTokenPending ? (
                        <CircularProgress size={12} />
                      ) : (
                        selectedToken?.symbol
                      )}
                    </InputAdornment>
                  ),
                }}
                error={!!form.formState.errors?.tokenAddress}
                helperText="The address of the token (must start with ‘0x’)"
                onBlur={(e) => patchFormValue("tokenAddress", e.target.value)}
              />
              {isGetConfigsPending ||
                (isGetCommodityTokensPending && (
                  <LinearProgress className="absolute top-0 left-0 right-0 h-0.5" />
                ))}
            </div>
          )}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            const symbol = tokens.find((t) => t.address == option)?.symbol;
            return (
              <li
                key={key}
                {...optionProps}
                className="p-2 flex justify-between hover:bg-hover cursor-pointer"
              >
                {symbol ?? option}
              </li>
            );
          }}
        />

        <div className="mt-1 flex flex-col gap-1">
          <label className="text-text-1">Notify When</label>
          <div className="w-full flex flex-col gap-2 text-sm border rounded-sm py-2">
            {AccountBalanceNotifyConditions.map((condition, idx) => {
              const isChecked = form.watch(`notifyOn.${idx}.checked`);
              return (
                <div key={condition} className="flex items-center">
                  <Checkbox
                    size="small"
                    checked={isChecked}
                    onChange={(_, checked) =>
                      patchFormValue(`notifyOn.${idx}.checked`, checked)
                    }
                  />
                  <div
                    className={classNames("flex items-center gap-3", {
                      "opacity-50": !isChecked,
                    })}
                  >
                    Account balance is{" "}
                    {TreasuryAccountBalanceNotifyConditionText[condition]}
                    <TextField
                      className="w-24"
                      {...form.register(`notifyOn.${idx}.amount`)}
                      variant="outlined"
                      size="small"
                      disabled={!isChecked}
                      inputProps={{ className: "py-1" }}
                      error={!!form.formState.errors?.notifyOn?.[idx]?.amount}
                    />
                    {selectedToken?.symbol}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <footer className="modal-footer flex flex-row-reverse gap-4">
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save
        </LoadingButton>

        <Button type="button" variant="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </footer>
    </form>
  );
}
