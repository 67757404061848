import { OpenInNew } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import NoRowsOverlay from "../../../components/NoRowsOverlay/NoRowsOverlay";
import { useModal } from "../../../providers/ModalProvider";
import { InvestPoolInvests, TokenTransferTx } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import { BlockchainTxStatus } from "../../blockchain/types";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import InvestPoolInvestTxs from "./InvestPoolInvestTxs";
import BlockchainAddressBook from "../../blockchain/components/BlockchainAddressBook";

interface IProps {
  txs: InvestPoolInvests["txs"];
  className?: string;
}

export default function InvestPoolInvestsTable({ txs, className }: IProps) {
  const config = useSelector(selectBlockchainConfigs);
  const [rows, setRows] = useState([]);
  const { openModal } = useModal();

  const handleTransactionsClick = (txIds: TokenTransferTx["id"][]) => {
    openModal((props) => <InvestPoolInvestTxs {...props} ids={txIds} />, {
      closeOnClickOutside: true,
      hideCloseButton: false,
    });
  };

  const cols: GridColDef[] = useMemo(
    () =>
      [
        {
          field: "rowNo",
          headerName: "",
          width: 50,
          sortable: false,
        },
        {
          field: "from",
          headerName: "From",
          renderCell: ({ value }) => <BlockchainAddressBook address={value} />,
          flex: 1,
        },
        {
          field: "amount",
          headerName: "Amount",
          renderCell: ({ row }: { row: (typeof txs)[0] }) =>
            `${formatNumberWithSeparator(row.valueFormatted)} ${
              row.tokenSymbol
            }`,
          flex: 1,
        },
        {
          field: "status",
          headerName: "Status",
          renderCell: ({ value }) => (
            <span
              className={classNames("txs-list-row__col", {
                "text-yellow-500": value == BlockchainTxStatus.Pending,
                "text-red-500": value == BlockchainTxStatus.Failed,
              })}
            >
              {value}
            </span>
          ),
          flex: 1,
        },
        {
          field: "txs",
          headerName: "Transactions",
          renderCell: ({ row }: { row: (typeof txs)[0] }) => (
            <OpenInNew
              className="link"
              fontSize="small"
              onClick={() => handleTransactionsClick(row.ids)}
            />
          ),
          headerAlign: "center",
          align: "center",
          width: 120,
          sortable: false,
        },
      ] as GridColDef[],
    []
  );

  useEffect(() => {
    setRows(
      (txs?.map((tx, index) => ({
        ...tx,
        rowNo: index + 1,
      })) as any) || []
    );
  }, [txs]);

  return (
    <DataGrid
      className={className}
      columns={cols}
      rows={rows}
      getRowId={({ from }) => from}
      disableColumnMenu
      disableSelectionOnClick
      style={{ maxHeight: "calc(100vh - 400px)" }}
      autoHeight
      components={{
        NoRowsOverlay: () => <NoRowsOverlay emptyMessage="No Investments" />,
      }}
    />
  );
}
