import { createAsyncThunk } from "@reduxjs/toolkit";
import { blockchainApi } from "../../http/blockchain.api";
import {
  AppPage,
  AppRefreshStatus,
  Notification,
  NotificationType,
} from "../../types";
import { appActions } from "../app/app.state";
import { notificationsActions } from "../notifications/notifications.state";
import { RootState } from "../store";
import listenerMiddleware from "../store/middlewares/listener.middleware";
import { BlockChainState } from "./blockchain.state";
import { SmartDealsFilters } from "./types/smart-deals-filters.type";

export const getSmartDealsAction = createAsyncThunk(
  "blockchain/getSmartDeals",
  async (
    {
      page,
      pageSize,
      filters,
    }: {
      page?: number;
      pageSize?: number;
      filters?: Partial<SmartDealsFilters>;
    },
    thunk
  ) => {
    const blockchainState: BlockChainState = (<RootState>thunk.getState())
      .blockchain;
    page = page ?? blockchainState.pagination.page;
    pageSize = pageSize ?? blockchainState.pagination.pageSize;
    filters = {
      ...blockchainState.filters,
      ...(filters || {}),
    };
    const paginatedSmartDeals = await blockchainApi.getSmartDeals({
      from: page * pageSize,
      take: pageSize,
      filters: JSON.stringify(filters),
    });
    return {
      paginatedSmartDeals,
      page,
      pageSize,
      filters: <SmartDealsFilters>filters,
    };
  }
);

export const getSmartDealsStatusCountsAction = createAsyncThunk(
  "blockchain/getSmartDealsStatusCounts",
  async ({ changeRefresh }: { changeRefresh: boolean }, thunk) => {
    try {
      changeRefresh &&
        thunk.dispatch(
          appActions.changeRefresh({
            status: AppRefreshStatus.InProgress,
            page: AppPage.SmartDeals,
          })
        );
      const response = await blockchainApi.getSmartDealsStatusCounts();
      return response;
    } finally {
      changeRefresh &&
        thunk.dispatch(
          appActions.changeRefresh({
            status: AppRefreshStatus.Idle,
            page: AppPage.SmartDeals,
          })
        );
    }
  }
);

export const getBlockchainAddressBooksAction = createAsyncThunk(
  "blockchain/getBlockchainAddressBooks",
  async () => {
    return await blockchainApi.getBlockchainAddressBooks();
  }
);

listenerMiddleware.startListening({
  actionCreator: notificationsActions.notificationReceived,
  effect: (action, listenerApi) => {
    const notificationType = (action.payload as Notification)?.type;
    if (
      [
        NotificationType.PromptPayRequestUnderReview,
        NotificationType.PromptPayRequestApproved,
        NotificationType.PromptPayRequestRejected,
      ].some((type) => type == notificationType)
    ) {
      listenerApi.dispatch(getSmartDealsAction({}));
      listenerApi.dispatch(
        getSmartDealsStatusCountsAction({ changeRefresh: false })
      );
    }
  },
});
