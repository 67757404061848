import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import theme from "./common/theme";
import "./index.scss";
import MainLayoutHeaderSlotProvider from "./layouts/MainLayout/providers/MainLayouHeaderSlotProvider";
import BlockchainProvider from "./modules/blockchain/providers/BlockchainProvider";
import store from "./modules/store";
import BottomSheetProvider from "./providers/BottomSheetProvider";
import ImageViewProvider from "./providers/ImagePreviewProvider";
import LocaleProvider from "./providers/LocaleProvider";
import MeProvider from "./providers/MeProvider";
import ModalProvider from "./providers/ModalProvider";
import WsProvider from "./providers/WsProvider";
import "./utils/array-utils";
import "./utils/number-utils";
import "./utils/string-utils";
import MenuProvider from "./providers/MenuProvider";
import BlockchainAddressBookProvider from "./providers/BlockchainAddressBookProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <ImageViewProvider>
            <WsProvider>
              <BlockchainProvider>
                <LocaleProvider>
                  <MainLayoutHeaderSlotProvider>
                    <MenuProvider>
                      <ModalProvider>
                        <BlockchainAddressBookProvider>
                          <BottomSheetProvider>
                            <MeProvider>
                              <App />
                            </MeProvider>
                          </BottomSheetProvider>
                        </BlockchainAddressBookProvider>
                      </ModalProvider>
                    </MenuProvider>
                  </MainLayoutHeaderSlotProvider>
                </LocaleProvider>
              </BlockchainProvider>
            </WsProvider>
          </ImageViewProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
