import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "../../types";
import { BlockchainAddressBook, SmartDeal } from "./types";
import { SmartDealsFilters } from "./types/smart-deals-filters.type";
import { SmartDealsStatusFilter } from "./types/smart-deals-status-filter.type";
import { DEFAULTS } from "../../common/constants";
import { addCommonReducers } from "../../utils/store-utils";
import {
  getBlockchainAddressBooksAction,
  getSmartDealsAction,
  getSmartDealsStatusCountsAction,
} from "./blockchain.effects";

export const BLOCKCHAIN_STATE_NAME = "blockchain";

export interface BlockChainState {
  smartDeals: SmartDeal[];
  statusCounts: Record<SmartDealsStatusFilter, number>;
  filters: SmartDealsFilters;
  pagination: Pagination;
  addressBooks: BlockchainAddressBook[];
}

export const blockchainState = createSlice({
  name: BLOCKCHAIN_STATE_NAME,
  initialState: {
    smartDeals: [],
    statusCounts: {
      All: 0,
      Draft: 0,
      Blockchain: 0,
    },
    filters: {
      status: SmartDealsStatusFilter.All,
      orderBy: {
        field: "createdAt",
        sort: "desc",
      },
    },
    pagination: {
      page: 0,
      pageSize: DEFAULTS.PageSize,
      total: 0,
    },
  } as BlockChainState,
  reducers: {
    saveAddressBook: (state, action) => {
      state.addressBooks = [
        ...state.addressBooks.filter(
          (ab) => ab.address !== action.payload.address
        ),
        action.payload,
      ];
    },
    deleteAddressBook: (state, action) => {
      state.addressBooks = state.addressBooks.filter(
        (ab) => ab.address !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmartDealsAction.fulfilled, (state, action) => {
        const { paginatedSmartDeals, page, pageSize, filters } = action.payload;
        state.smartDeals = paginatedSmartDeals.data;
        state.pagination.total = paginatedSmartDeals.total;
        state.pagination.page = page;
        state.pagination.pageSize = pageSize;
        state.filters = filters;
      })
      .addCase(getSmartDealsStatusCountsAction.fulfilled, (state, action) => {
        state.statusCounts = action.payload;
      })
      .addCase(getBlockchainAddressBooksAction.fulfilled, (state, action) => {
        state.addressBooks = action.payload;
      });
    addCommonReducers(builder, BLOCKCHAIN_STATE_NAME);
  },
});

export const blockchainActions = blockchainState.actions;
export const blockchainReducer = blockchainState.reducer;
