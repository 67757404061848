import { InboxOutlined, OpenInNew } from "@mui/icons-material";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlockchainConfigurations, CommodityTokenTx } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import WidgetList from "../../dashboard/components/WidgetList";
import WidgetPaginator from "../../dashboard/components/WidgetPaginator";
import { selectBlockchainConfigs } from "../../settings/settings.selectors";
import { getTreasuryTransactionsAction } from "../treasury.effects";
import {
  selectTransactionsCategoriesPageSizes,
  selectTreasuryTransactions,
} from "../treasury.selectors";
import { TreasuryInternalTransactionsFilters as TreasuryInternalTransactionsFiltersType } from "../types/treasury-internal-transactions-filters.type";
import { TreasuryTransactionCategory } from "../types/treasury-transactions-category.type";
import TreasuryInternalTransactionsFilters from "./TreasuryInternalTransactionsFilters";
import BlockchainAddressBook from "../../blockchain/components/BlockchainAddressBook";

const renderHeader = () => (
  <div className="flex justify-between items-center gap-10">
    <span className="flex-1 pl-1">Time</span>
    <span className="flex-[0_0_70px]">Type</span>
    <span className="flex-1 text-right">Asset</span>
    <span className="flex-1 text-right">Value</span>
    <span className="flex-1 text-center hidden md:block">From</span>
    <span className="flex-[0_0_30px] text-center">TX</span>
  </div>
);

const renderItem = (
  tx: CommodityTokenTx,
  configs: BlockchainConfigurations
) => {
  return (
    <div className="flex justify-between items-center gap-10 text-sm">
      <span className="flex-1 pl-1">
        {moment(tx.blockTimestamp).fromNow().capitalizeFirstLetter()}
      </span>

      <span
        className={classNames(
          "flex-[0_0_70px]",
          `text-${tx.action.toLowerCase()}`
        )}
      >
        {tx.action.toUpperCase()}
      </span>

      <span
        className={classNames(
          "flex-1 text-right",
          `text-${tx.action.toLowerCase()}`
        )}
      >
        {formatNumberWithSeparator(tx.amount.toFixed(2))} {tx.rwaSymbol}
      </span>

      <span
        className={classNames(
          "flex-1 text-right",
          `text-${tx.action.toLowerCase()}`
        )}
      >
        {formatNumberWithSeparator(tx.totalPrice.toFixed(2))} {tx.tokenSymbol}
      </span>

      <BlockchainAddressBook
        className="flex-1 text-center hidden md:flex justify-center"
        address={tx.account}
      />

      <span className="flex-[0_0_30px] text-center">
        <a href={tx.txUrl} target="_blank">
          <OpenInNew className="text-base" />
        </a>
      </span>
    </div>
  );
};

export default function TreasuryInternalTransactions() {
  const configs = useSelector(selectBlockchainConfigs);
  const paginatedTransactions = useSelector(selectTreasuryTransactions);
  const pageSizes = useSelector(selectTransactionsCategoriesPageSizes);
  const [page, setPage] = useState(0);
  const [filters, setFilters] =
    useState<TreasuryInternalTransactionsFiltersType>();
  const [invalidateTransactionsObj, setInvalidateTransactionsObj] = useState(
    {}
  );
  const dispatch = useDispatch();

  const getTreasuryTransactions = useCallback(
    _.debounce(
      (page: number, filters: TreasuryInternalTransactionsFiltersType) => {
        dispatch(
          getTreasuryTransactionsAction({
            page,
            filters: filters
              ? ({
                  ...filters,
                  timeRange: filters.timeRange
                    ? {
                        start: moment(filters.timeRange.start)
                          .startOf("day")
                          .valueOf(),
                        end: moment(filters.timeRange.end)
                          .endOf("day")
                          .valueOf(),
                      }
                    : null,
                } as TreasuryInternalTransactionsFiltersType)
              : null,
          })
        );
      },
      100
    ),
    []
  );

  useEffect(() => {
    getTreasuryTransactions(page, filters);
  }, [page, invalidateTransactionsObj]);

  useEffect(() => {
    setPage(0);
    setInvalidateTransactionsObj({});
  }, [filters]);

  return paginatedTransactions?.category ==
    TreasuryTransactionCategory.Internal ? (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex-1 px-4 pt-2.5">
          <WidgetList
            items={paginatedTransactions.payload.data}
            getKey={(item) => item.txHash}
            renderHeader={renderHeader}
            renderItem={(item) => renderItem(item as CommodityTokenTx, configs)}
            renderEmpty={() => (
              <div className="mt-24 w-full flex justify-center gap-1 opacity-75">
                <InboxOutlined /> No transactions found
              </div>
            )}
          />
        </div>
        <div className="WidgetFooter flex justify-end">
          <WidgetPaginator
            page={page}
            pageSize={pageSizes[TreasuryTransactionCategory.Internal]}
            total={paginatedTransactions.payload.total || 0}
            onPageChange={setPage}
          />
        </div>
      </div>
      <TreasuryInternalTransactionsFilters
        value={filters}
        onChange={setFilters}
      />
    </>
  ) : null;
}
