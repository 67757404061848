import { ChipTypeMap, LinearProgressProps } from "@mui/material";
import cronstrue from "cronstrue";
import { CONSTANTS } from "../common/constants";
import {
  BlockchainEvent,
  BlockchainEventType,
  BlockchainTx,
  BlockchainTxStatus,
  BlockchainTxType,
  ProformaInvoice,
  SmartDealDocumentState,
  SmartDealPartyType,
  SmartDealPaymentAction,
  SmartDealPaymentActionType,
  SmartDealPaymentBlockchainInfo,
  SmartDealState,
} from "../modules/blockchain/types";
import { SmartDealsStatusFilter } from "../modules/blockchain/types/smart-deals-status-filter.type";
import { CompaniesStatusFilter } from "../modules/companies/types/companies-status-filter.type";
import { FindOutWhyType } from "../modules/dashboard/types/find-out-why.type";
import { OpportunityStatusFilter } from "../modules/offers/types/opportunity-status-filter.type";
import { PaymentsStatusFilter } from "../modules/payments/types/payments-status.filter.type";
import { PlanUpgradeRequestStatus } from "../modules/plans/types/plan-upgrade-request-status.type";
import { PlanFiltersTabType } from "../modules/plans/types/plans-filters-tab-type.type";
import {
  AccountBalanceNotifyConfig,
  CompanyAccountState,
  CompanyAccountStateOrders,
  CompanyRoles,
  Currency,
  ImageResizeMode,
  InvestPoolState,
  InvestPoolStatus,
  InvestPoolType,
  NotificationType,
  OpportunityState,
  OpportunityType,
  Payment,
  PaymentMethod,
  PlanType,
  PurposeOfUse,
  Roles,
  ScheduleName,
  TimeWindow,
  TransactionStatus,
  TreasuryAccountBalance,
  UserAccountState,
  UserAccountStateOrders,
} from "../types";
import { PlanSubscription } from "../types/plan-subscription.type";
import { SmartDealPromptPayStatus } from "../types/smart-deal-prompt-pay-status.type";
import { toShortHash } from "./blockchain-utils";
import { TreasuryTransactionCategory } from "../modules/treasury/types/treasury-transactions-category.type";

export function inRoles(role: Roles, roles: Roles[]): boolean {
  return roles?.includes(role);
}

export function inCompanyRoles(
  role: CompanyRoles,
  roles: CompanyRoles[]
): boolean {
  return roles?.includes(role);
}

export function toPurposeOfUse(roles: CompanyRoles[]): PurposeOfUse {
  if (inCompanyRoles(CompanyRoles.Importer, roles)) {
    if (inCompanyRoles(CompanyRoles.Exporter, roles)) return PurposeOfUse.Both;
    return PurposeOfUse.Importer;
  }
  return PurposeOfUse.Exporter;
}

export function toRoles(purposeOfUse: PurposeOfUse): CompanyRoles[] {
  switch (purposeOfUse) {
    case PurposeOfUse.Importer:
      return [CompanyRoles.Importer];
    case PurposeOfUse.Exporter:
      return [CompanyRoles.Exporter];
    case PurposeOfUse.Both:
      return [CompanyRoles.Importer, CompanyRoles.Exporter];
    default:
      return [];
  }
}

export const CompaniesStatusFilters: CompaniesStatusFilter[] = [
  CompaniesStatusFilter.All,
  CompaniesStatusFilter.UnderReview,
  CompaniesStatusFilter.Rejected,
  CompaniesStatusFilter.Active,
];

export const CompaniesStatusFilterText: Record<CompaniesStatusFilter, string> =
  {
    All: "All Companies",
    UnderReview: "Under Review",
    Rejected: "Rejected",
    Active: "Active",
  };

export const PaymentsStatusFilters: PaymentsStatusFilter[] = [
  PaymentsStatusFilter.All,
  PaymentsStatusFilter.Succeeded,
  PaymentsStatusFilter.Failed,
];

export const PaymentsStatusFilterText: Record<PaymentsStatusFilter, string> = {
  All: "All Transactions",
  Succeeded: "Succeeded",
  Failed: "Failed",
};

export const PlanFiltersTabTypeText: Record<PlanFiltersTabType, string> = {
  [PlanFiltersTabType.Subscription]: "Plan Subscriptions",
  [PlanFiltersTabType.Upgrade]: "Plan Upgrade Requests",
};

export const OpportunitiesStatusFilters: OpportunityStatusFilter[] = [
  OpportunityStatusFilter.All,
  OpportunityStatusFilter.UnderReview,
  OpportunityStatusFilter.Rejected,
  OpportunityStatusFilter.Active,
];

export const OpportunitiesStatusFilterText: (
  type: OpportunityType
) => Record<OpportunityStatusFilter, string> = (type) => ({
  All: `All ${type}s`,
  UnderReview: "Under Review",
  Rejected: "Rejected",
  Active: "Active",
  Expired: "Expired",
  Canceled: "Canceled",
});

export const CompanyAccountStateText: Record<CompanyAccountState, string> = {
  Draft: "Registration",
  BuyPlan: "Payment",
  CompleteProfile: "Complete Profile",
  UnderReview: "Under Review",
  Active: "Active",
  Rejected: "Rejected",
  Canceled: "Canceled",
};

export const OpportunityStateText: Record<OpportunityState, string> = {
  UnderReview: "Under Review",
  Active: "Active",
  Rejected: "Rejected",
  Expired: "Expired",
  Canceled: "Canceled",
};

export const PlanUpgradeRequestStatusText: Record<
  PlanUpgradeRequestStatus,
  string
> = {
  UnderReview: "Under Review",
  Approved: "Approved",
  Rejected: "Rejected",
};

export const ScheduleNameText: Record<ScheduleName, string> = {
  OpportunityExpirationJob: "Check opportunity expiration",
  FlushBlockchainEventsJob: "Flush blockchain events",
  FlushInvestPoolTransferEventsJob: "Flush investment pool transfer events",
  BackupDatabaseJob: "Backup database",
};

export const ImageResizeModes: ImageResizeMode[] = [
  ImageResizeMode.Contain,
  ImageResizeMode.Cover,
  ImageResizeMode.Fill,
  ImageResizeMode.Inside,
  ImageResizeMode.Outside,
];

export const ImageResizeModeDescription: Record<ImageResizeMode, string> = {
  contain:
    'Preserving aspect ratio, contain within both provided dimensions using "letterboxing" where necessary.',
  cover:
    "Preserving aspect ratio, ensure the image covers both provided dimensions by cropping/clipping to fit.",
  fill: "Ignore the aspect ratio of the input and stretch to both provided dimensions.",
  inside:
    "Preserving aspect ratio, resize the image to be as large as possible while ensuring its dimensions are less than or equal to both those specified.",
  outside:
    "Preserving aspect ratio, resize the image to be as small as possible while ensuring its dimensions are greater than or equal to both those specified.",
};

export function getTransactionStatusText(
  status: TransactionStatus,
  failedStage: string
): string {
  switch (status) {
    case TransactionStatus.Succeeded:
      return status;

    case TransactionStatus.Failed:
      return failedStage ? `@${failedStage}` : status;
  }
}

export function getSubscriptionStatusText(isActive: boolean): string {
  return isActive ? "Active" : "Expired";
}

export function getSubscriptionStatusColor(
  isActive: boolean
): ChipTypeMap["props"]["color"] {
  return isActive ? "paleGreen" : "default";
}

export function getCompanyAccountStateColor(
  state: CompanyAccountState
): ChipTypeMap["props"]["color"] {
  switch (state) {
    case CompanyAccountState.Draft:
    case CompanyAccountState.BuyPlan:
      return "default";

    case CompanyAccountState.CompleteProfile:
      return "info";

    case CompanyAccountState.UnderReview:
      return "warning";

    case CompanyAccountState.Active:
      return "success";

    case CompanyAccountState.Rejected:
    case CompanyAccountState.Canceled:
      return "error";
  }
}

export function getPlanUpgradeRequestStatusColor(
  state: PlanUpgradeRequestStatus
): ChipTypeMap["props"]["color"] {
  switch (state) {
    case PlanUpgradeRequestStatus.UnderReview:
      return "warning";

    case PlanUpgradeRequestStatus.Approved:
      return "success";

    case PlanUpgradeRequestStatus.Rejected:
      return "error";
  }
}

export function getOpportunityStateColor(
  state: OpportunityState
): ChipTypeMap["props"]["color"] {
  switch (state) {
    case OpportunityState.UnderReview:
      return "warning";

    case OpportunityState.Active:
      return "success";

    case OpportunityState.Rejected:
    case OpportunityState.Canceled:
      return "error";

    case OpportunityState.Expired:
      return "default";
  }
}

export function getPurposeOfUseColor(
  purposeOfUse: PurposeOfUse
): ChipTypeMap["props"]["color"] {
  switch (purposeOfUse) {
    case PurposeOfUse.Importer:
      return "seaGreen";

    case PurposeOfUse.Exporter:
      return "palePink";

    case PurposeOfUse.Both:
      return "yellow";
  }
}

export function getCompanyStatusIndicatorText(status: CompanyAccountState) {
  switch (status) {
    case CompanyAccountState.Draft:
    case CompanyAccountState.BuyPlan:
    case CompanyAccountState.CompleteProfile:
      return "Incomplete";

    case CompanyAccountState.UnderReview:
      return "Pending";

    case CompanyAccountState.Active:
      return "Approved";

    case CompanyAccountState.Rejected:
      return "Rejected";

    case CompanyAccountState.Canceled:
      return "Canceled";
  }
}

export function getPlanUpgradeRequestStatusIndicatorText(
  status: PlanUpgradeRequestStatus
) {
  switch (status) {
    case PlanUpgradeRequestStatus.UnderReview:
      return "Pending";

    case PlanUpgradeRequestStatus.Approved:
      return "Approved";

    case PlanUpgradeRequestStatus.Rejected:
      return "Rejected";
  }
}

export function getOpportunityStatusIndicatorText(status: OpportunityState) {
  switch (status) {
    case OpportunityState.UnderReview:
      return "Pending";

    case OpportunityState.Active:
      return "Approved";

    case OpportunityState.Rejected:
      return "Rejected";

    case OpportunityState.Expired:
      return "Expired";

    case OpportunityState.Canceled:
      return "Canceled";
  }
}

export function getCompanyStatusIndicatorColor(status: CompanyAccountState) {
  switch (status) {
    case CompanyAccountState.Draft:
    case CompanyAccountState.BuyPlan:
    case CompanyAccountState.CompleteProfile:
      return "blue-500";

    case CompanyAccountState.UnderReview:
      return "orange-500";

    case CompanyAccountState.Active:
      return "green-500";

    case CompanyAccountState.Rejected:
    case CompanyAccountState.Canceled:
      return "red-500";
  }
}

export function getPlanUpgradeRequestColor(status: PlanUpgradeRequestStatus) {
  switch (status) {
    case PlanUpgradeRequestStatus.UnderReview:
      return "orange-500";

    case PlanUpgradeRequestStatus.Approved:
      return "green-500";

    case PlanUpgradeRequestStatus.Rejected:
      return "red-500";
  }
}

export function getOpportunityStatusIndicatorColor(status: OpportunityState) {
  switch (status) {
    case OpportunityState.UnderReview:
      return "orange-500";

    case OpportunityState.Active:
      return "green-500";

    case OpportunityState.Rejected:
    case OpportunityState.Canceled:
      return "red-500";

    case OpportunityState.Expired:
      return "gray-500";
  }
}

export function getPlanTypeColor(
  type: PlanType
): ChipTypeMap["props"]["color"] {
  switch (type) {
    case PlanType.Freemium:
      return "smoke";

    case PlanType.Starter:
      return "slateBlue";

    case PlanType.Growth:
      return "info";

    case PlanType.Scale:
      return "purple";

    case PlanType.Unlimited:
      return "bezh";
  }
}

export function getPaymentMethodColor(
  method: PaymentMethod
): ChipTypeMap["props"]["color"] {
  switch (method) {
    case PaymentMethod.Stripe:
      return "black";

    case PaymentMethod.Invoice:
      return "bezh";
  }
}

export function getTransactionStatusColor(
  status: TransactionStatus
): ChipTypeMap["props"]["color"] {
  switch (status) {
    case TransactionStatus.Succeeded:
      return "paleGreen";

    case TransactionStatus.Failed:
      return "palePink";
  }
}

export function isEmailVerified(stateOrder: number) {
  return stateOrder > UserAccountStateOrders[UserAccountState.EmailNotVerified];
}

export function getExpiredPlanSubscriptions(
  planSubscriptions: PlanSubscription[],
  activePlanSubscription: PlanSubscription
): PlanSubscription[] {
  return (
    planSubscriptions?.filter((ps) => ps.id !== activePlanSubscription?.id) ||
    []
  );
}

export function toAbsoluteUrl(relativeUrl: string) {
  return `${CONSTANTS.CoreServiceUrl}/${CONSTANTS.ApiVersionPath}/${relativeUrl}`;
}

export function canApproveCompany(state: CompanyAccountState): boolean {
  return (
    state === CompanyAccountState.UnderReview ||
    state === CompanyAccountState.Rejected
  );
}

export function canRejectCompany(state: CompanyAccountState): boolean {
  return (
    CompanyAccountStateOrders[state] >=
      CompanyAccountStateOrders[CompanyAccountState.UnderReview] &&
    state !== CompanyAccountState.Rejected
  );
}

export function canApproveOpportunity(state: OpportunityState): boolean {
  return (
    state === OpportunityState.UnderReview ||
    state === OpportunityState.Rejected
  );
}

export function canRejectOpportunity(state: OpportunityState): boolean {
  return (
    state === OpportunityState.UnderReview || state === OpportunityState.Active
  );
}

export function getCurrencySymbol(currency: string) {
  switch (currency?.toUpperCase() as Currency) {
    case Currency.EUR:
      return "€";

    default:
      return "$";
  }
}

export function toSmartDealTxApprover(approver: SmartDealPartyType): number {
  switch (approver) {
    case SmartDealPartyType.Importer:
      return 1;

    case SmartDealPartyType.Exporter:
      return 2;

    case SmartDealPartyType.Admin:
      return 3;
  }
}

export function getPaymentsTotal(payments: Payment[]) {
  return payments.reduce(
    (total, payment) =>
      (total +=
        payment.status === TransactionStatus.Succeeded ? payment.total : 0),
    0
  );
}

export function toYesOrNoText(value: boolean): string {
  return value ? "Yes" : "No";
}

export function getEnableOrDisableText(value: boolean): string {
  return value ? "Enable" : "Disable";
}

export function getCronExpressionText(exp: string): string {
  try {
    return cronstrue.toString(exp, { verbose: true });
  } catch {
    return "";
  }
}

export function getTimeWindowText(window: TimeWindow): string {
  switch (window) {
    case "all":
      return "All Time";

    case 1:
      return "Today";

    default:
      return `Last ${window} days`;
  }
}

export function getFindOutWhyText(type: FindOutWhyType): string {
  switch (type) {
    case FindOutWhyType.RegisteredButNotVerifiedEmail:
      return `New companies that sign up but didn't verify their email`;

    case FindOutWhyType.VerifiedEmailButNoPlan:
      return `Companies that haven't bought any plan`;

    case FindOutWhyType.HavePlanButNotCompletedProfile:
      return `Companies that have plan but didn't compelete their profile`;

    case FindOutWhyType.ApprovedButNoOpportunities:
      return `Companies that are approved but haven't posted any opportunities`;
  }
}

export const NotificationTypeText: Partial<Record<NotificationType, string>> = {
  CompanyUnderReview: "Company Under Review",
  OpportunityUnderReview: "Opportunity Under Review",
  PlanUpgradeRequestUnderReview: "Upgrade Plan Request",
  PromptPayRequestUnderReview: "Prompt Pay Request",
};

export const SmartDealsStatusFilters: SmartDealsStatusFilter[] = [
  SmartDealsStatusFilter.All,
  SmartDealsStatusFilter.Draft,
  SmartDealsStatusFilter.Blockchain,
  SmartDealsStatusFilter.PromptPayUnderReview,
];

export const SmartDealsStatusFilterText: Record<
  SmartDealsStatusFilter,
  string
> = {
  All: "All Smart Deals",
  Draft: "Draft",
  Blockchain: "Blockchain",
  PromptPayUnderReview: "PP Under Review",
};

export function getSmartDealStateColor(
  state: SmartDealState
): ChipTypeMap["props"]["color"] {
  switch (state) {
    case SmartDealState.Draft:
      return "yellow";

    case SmartDealState.Blockchain:
      return "seaGreen";
  }
}

export function getSmartDealDocumentStateColor(
  state: SmartDealDocumentState
): ChipTypeMap["props"]["color"] {
  switch (state) {
    case SmartDealDocumentState.Draft:
      return "yellow";

    case SmartDealDocumentState.Blockchain:
      return "seaGreen";

    case SmartDealDocumentState.Approved:
      return "info";
  }
}

export function getSmartDealStatusIndicatorColor(status: SmartDealState) {
  switch (status) {
    case SmartDealState.Draft:
      return "yellow-500";

    case SmartDealState.Blockchain:
      return "green-500";
  }
}

export function getSmartDealDocumentStatusIndicatorColor(
  status: SmartDealDocumentState
) {
  switch (status) {
    case SmartDealDocumentState.Draft:
      return "yellow-500";

    case SmartDealDocumentState.Blockchain:
      return "green-500";

    case SmartDealDocumentState.Approved:
      return "blue-500";
  }
}

export function getBlockchainTxStatusIndicatorColor(
  status: BlockchainTxStatus | null
) {
  switch (status) {
    case BlockchainTxStatus.Pending:
      return "yellow-500";

    case BlockchainTxStatus.Confirmed:
      return "green-500";

    case BlockchainTxStatus.Failed:
      return "red-500";

    default:
      return "gray-300";
  }
}

export function getBlockChainEventData(
  tx: BlockchainTx,
  type: BlockchainEventType
): BlockchainEvent["data"] {
  return tx?.events?.find((e) => e.type === type)?.data || [];
}

export function getSmartDealPaymentStatusIndicatorColor(isClosed: boolean) {
  return isClosed ? "green-500" : "yellow-500";
}

export function getSmartDealPaymentStatusIndicatorText(isClosed: boolean) {
  return isClosed ? "Closed" : "Open";
}

export function getExecutablePaymentActionTypes(
  paymentInfo: SmartDealPaymentBlockchainInfo
): SmartDealPaymentActionType[] {
  const actions: SmartDealPaymentActionType[] = [];
  const {
    upfrontPaymentMade,
    upfrontPaymentReturned,
    laterPaymentMade,
    laterPaymentReturned,
    isClose,
  } = paymentInfo;

  if (isClose) {
    return [];
  }
  if (!upfrontPaymentMade) {
    actions.push(SmartDealPaymentActionType.ReleaseUpfrontPayment);
  }
  if (!upfrontPaymentReturned && !upfrontPaymentMade) {
    actions.push(SmartDealPaymentActionType.RevertUpfrontPayment);
  }
  if (!laterPaymentMade && upfrontPaymentMade) {
    actions.push(SmartDealPaymentActionType.ReleaseFullPayment);
  }
  if (!laterPaymentReturned && !laterPaymentMade) {
    if (upfrontPaymentMade || upfrontPaymentReturned)
      actions.push(SmartDealPaymentActionType.RevertFullPayment);
  }

  return actions;
}

export function getExecutedPaymentActionTypes(
  paymentInfo: SmartDealPaymentBlockchainInfo
): SmartDealPaymentActionType[] {
  const actions: SmartDealPaymentActionType[] = [];
  const {
    upfrontPaymentMade,
    upfrontPaymentReturned,
    laterPaymentMade,
    laterPaymentReturned,
  } = paymentInfo;

  upfrontPaymentMade &&
    actions.push(SmartDealPaymentActionType.ReleaseUpfrontPayment);
  upfrontPaymentReturned &&
    actions.push(SmartDealPaymentActionType.RevertUpfrontPayment);
  laterPaymentMade &&
    actions.push(SmartDealPaymentActionType.ReleaseFullPayment);
  laterPaymentReturned &&
    actions.push(SmartDealPaymentActionType.RevertFullPayment);

  return actions;
}

export function getBlockChainEventsForPaymentActions(
  types: SmartDealPaymentActionType[]
): BlockchainEventType[] {
  let events: BlockchainEventType[] = [];

  if (
    types?.includes(SmartDealPaymentActionType.ReleaseUpfrontPayment) ||
    types?.includes(SmartDealPaymentActionType.RevertUpfrontPayment)
  ) {
    events.push(BlockchainEventType.FeePaid);
  }

  if (types?.includes(SmartDealPaymentActionType.ReleaseUpfrontPayment)) {
    events.push(BlockchainEventType.UpfrontPaymentReleased);
  }

  if (types?.includes(SmartDealPaymentActionType.ReleaseFullPayment)) {
    events.push(
      BlockchainEventType.FeePaid,
      BlockchainEventType.FullPaymentReleased
    );
  }

  if (types?.includes(SmartDealPaymentActionType.RevertFullPayment)) {
    events.push(BlockchainEventType.FullPaymentReversed);
  }

  return events;
}

export function hasAllPaymentEventsReceived(
  actions: SmartDealPaymentAction[]
): boolean {
  var receivedEventTypes = (actions || [])
    .map((action) => action.tx?.events || [])
    .flat()
    .map(({ type }) => type)
    .sort();

  const expectedEventTypes = getBlockChainEventsForPaymentActions(
    actions?.map(({ type }) => type)
  ).sort();

  if (receivedEventTypes.length < expectedEventTypes.length) return false;

  return expectedEventTypes.every(
    (eventType, idx) => eventType == receivedEventTypes[idx]
  );
}

export const SmartDealPaymentActionText: Record<
  SmartDealPaymentActionType,
  string
> = {
  [SmartDealPaymentActionType.ReleaseUpfrontPayment]: "Release Upfront Payment",
  [SmartDealPaymentActionType.RevertUpfrontPayment]: "Revert Upfront Payment",
  [SmartDealPaymentActionType.ReleaseFullPayment]: "Release Remaining Payment",
  [SmartDealPaymentActionType.RevertFullPayment]: "Revert Remaining Payment",
};

export function isBalanceSufficientForSmartDealPaymentAction(
  type: SmartDealPaymentActionType,
  paymentInfo: SmartDealPaymentBlockchainInfo,
  proformaInvoice: ProformaInvoice
): boolean {
  const {
    availablePaymentBalance,
    availableFeeBalance,
    paymentContractAddress,
    feeTokenAddress,
  } = paymentInfo;

  let requiredPayment, requiredFee;
  switch (type) {
    case SmartDealPaymentActionType.ReleaseUpfrontPayment:
    case SmartDealPaymentActionType.RevertUpfrontPayment:
      requiredPayment = proformaInvoice.deposit;
      requiredFee = paymentInfo.fixedFeePaid ? 0 : proformaInvoice.fixedFee;
      break;

    case SmartDealPaymentActionType.ReleaseFullPayment:
      requiredPayment = proformaInvoice.balance;
      requiredFee = paymentInfo.commissionFeePaid
        ? 0
        : proformaInvoice.commissionFee;
      break;

    case SmartDealPaymentActionType.RevertFullPayment:
      requiredPayment = proformaInvoice.balance;
      requiredFee = 0;
      break;
  }

  if (paymentContractAddress == feeTokenAddress) {
    return availablePaymentBalance >= requiredPayment + requiredFee;
  } else {
    return (
      availablePaymentBalance >= requiredPayment &&
      availableFeeBalance >= requiredFee
    );
  }
}

export const InvestPoolStateText: Record<InvestPoolState, string> = {
  NotStarted: "Not Started",
  InvestmentStarted: "Open for Investment",
  InvestmentClosed: "Before Staking",
  StakingStarted: "Staking 💤",
  StakingEnded: "Staking Finished",
  Settled: "Settled 🤝",
};

export function getInvestPoolStateColor(
  state: InvestPoolState
): ChipTypeMap["props"]["color"] & LinearProgressProps["color"] {
  switch (state) {
    case InvestPoolState.NotStarted:
      return "primary";

    case InvestPoolState.InvestmentStarted:
      return "info";

    case InvestPoolState.InvestmentClosed:
      return "secondary";

    case InvestPoolState.StakingStarted:
      return "purple";

    case InvestPoolState.StakingEnded:
      return "paleGreen";

    case InvestPoolState.Settled:
      return "gray";
  }
}

export function getInvestPoolStatusIndicatorColor(status: InvestPoolStatus) {
  switch (status) {
    case InvestPoolStatus.Draft:
      return "yellow-500";

    case InvestPoolStatus.Published:
      return "green-500";
  }
}

export function getNumberOfBlockchainTxEvents(tx: BlockchainTx): {
  total: number;
  received: number;
} {
  let total = 0;
  switch (tx.type) {
    case BlockchainTxType.CreateDoc:
    case BlockchainTxType.ReleaseUpfrontPayment:
    case BlockchainTxType.ReleaseFullPayment:
    case BlockchainTxType.RevertUpfrontPayment:
      total = 2;
      break;

    case BlockchainTxType.CreateDeal:
    case BlockchainTxType.SignDoc:
    case BlockchainTxType.CreatePayment:
    case BlockchainTxType.RevertFullPayment:
      total = 1;
      break;
  }

  return { total, received: tx.events?.length || 0 };
}

export const SmartDealPromptPayStatusColor: Record<
  SmartDealPromptPayStatus,
  string
> = {
  [SmartDealPromptPayStatus.UnderReview]: "stroke-yellow-500",
  [SmartDealPromptPayStatus.Approved]: "stroke-secondary",
  [SmartDealPromptPayStatus.Rejected]: "stroke-danger",
};

export const SmartDealPromptPayStatusText: Record<
  SmartDealPromptPayStatus,
  string
> = {
  [SmartDealPromptPayStatus.UnderReview]: "Under Review",
  [SmartDealPromptPayStatus.Approved]: "Approved",
  [SmartDealPromptPayStatus.Rejected]: "Rejected",
};

export function getTreasuryAccountBalanceName(
  accountBalance: TreasuryAccountBalance
): string {
  return accountBalance.accountAlias
    ? accountBalance.accountAlias
    : toShortHash(accountBalance.account);
}

export const TreasuryAccountBalanceNotifyConditionText: Record<
  AccountBalanceNotifyConfig["condition"],
  string
> = {
  GT: "higher than",
  LT: "lower than",
};

export const TreasuryTransactionCategoryText: Record<
  TreasuryTransactionCategory,
  string
> = {
  Internal: "Gateway Transactions",
  InvestPool: "Invest Pools Transactions",
  Dex: "DEXes Transactions",
};

export const InvestPoolTypeText: Record<InvestPoolType, string> = {
  PromptPay: "Prompt Pay",
};
